// custom typefaces
// import "typeface-montserrat"
// import "typeface-merriweather"

import "prismjs/themes/prism.css"

// import './static/assets/css/bootstrap.min.css'
// import './static/assets/css/font-awesome.min.css'
// import './static/assets/css/animate.min.css'
// import './static/assets/css/font-pizzaro.css'
// import './static/assets/css/style.css'
// import './static/assets/css/colors/red.css'
// import './static/assets/css/jquery.mCustomScrollbar.min.css'

import './src/styles/layout.css'
